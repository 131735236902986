@font-face {
  font-family: 'Karrik';
  src:
    url('./fonts/Karrik-Regular.woff') format('woff'),
    url('./fonts/Karrik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karrik-Italic';
  src:
    url('./fonts/Karrik-Italic.woff') format('woff'),
    url('./fonts/Karrik-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Travelcons';
  src:
    url('./fonts/Travelcons.woff') format('woff'),
    url('./fonts/Travelcons.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Anthony';
  src:
    url('./fonts/Anthony.woff') format('woff'),
    url('./fonts/Anthony.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
