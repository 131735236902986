.torrent-info-text {
  font-family: 'Anthony';
  position: absolute;
  color: white;
  top: 100px;
  font-size: 30px;
  margin: 0 40px;
  opacity: 0;
  transition: opacity ease-in 500ms;
  z-index: 1;
}

.torrent-info-text.mobile {
  top: 75px;
  margin: 0 10px;
  font-size: 20px;
}

.torrent-info-text a {
  text-decoration: none;
}

.torrent-info-text.hidden {
  opacity: 0; /* Fade out */
}

.torrent-info-text.visible {
  opacity: 1; /* Fade in */
}
