.loading-spinner {
  display: inline-block;
  border: 4px solid;
  border-radius: 100%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
